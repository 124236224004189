import "./singlerecord.css"
import { Link, useLocation, useNavigate } from "react-router-dom"
//import CommentSection from "../../components/commentsection/CommentSection"
import { convertFromRaw, Editor, EditorState } from 'draft-js';
import Navbar from "../../components/Navbar/Navbar";
import { AuthContext } from "../../context/authContext";
import { useContext, useEffect, useState } from "react";
import { makeRequest } from "../../axios";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import MediaQuery from "react-responsive";
import Footer from "../../components/footer/Footer";

import NavigationLeftGold from "../../assets/icons/nav-arrow-left-gold.svg";
import EditIcon from "../../assets/icons/edit-pencil-black.svg";
import RemoveIcon from "../../assets/icons/trash-black.svg";

import baseUrl from "../../baseUrl"

export default function SingleRecord(props) {
    const profilePicQuery = useQuery(["profilePics"], () =>
        makeRequest.get("https://"+baseUrl+"/api/getprofilepics", {mode: "cors"}).then((res) => {
            return res.data;
        })
    );

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'instant'});
    }, []);
    
    const navigate = useNavigate();
    const location = useLocation()
    const {user} = useContext(AuthContext)
    const queryClient = useQueryClient();
    

    const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false)

    const data = location.state?.data

    const headers = {
        'Authorization': 'Bearer ' +localStorage.getItem('loginToken')
      }

    const mutation = useMutation(
        (deleteRecord) => {
          return makeRequest.delete(
            
                "https://"+baseUrl+"/api/removerecord", {data:deleteRecord}
            ).then(response => {
                console.log(response.data);
              })
              .catch(error => {
                if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('Error', error.message);
                }
                console.log(error.config);
              });;
        },
        {
            onSuccess: (data, variables, context) => {
                // Check if there's an error message returned by the server
                if (data && data.error) {
                    console.error("Error:", data.error); // Log the error message
                    // Optionally, you can throw an error here to be caught by the mutation's onError handler
                    throw new Error(data.error);
                } else {
                    // If no error, log success message and invalidate and refetch
                    queryClient.invalidateQueries([variables.genre.toLowerCase()+"records"]);
                }
            },
            onError: (error) => {
                // Handle any errors that occur during the mutation
                console.error("Mutation error:", error);
                // Optionally, you can display an error message to the user or perform other actions
            }
        }
    );

    
    if(!data){
        navigate("/");
        return;
    }

    let reviewerPic = ""
    if(profilePicQuery.data){
        reviewerPic = data.reviewedBy == "Claes" ? profilePicQuery.data[0] : profilePicQuery.data[1]
    }

    console.log(data)

    

    let editorState = EditorState.createEmpty()

    if(data && data.review != ""){
        const contentState = convertFromRaw(JSON.parse(data.review))
        editorState = EditorState.createWithContent(contentState)
    }

    function formatDate(string){
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return new Date(string).toLocaleDateString([],options);
    }

    
    
    function handleRemoveClick(){
        setShowRemoveConfirmation(true)
    }

    const handleConfirmYes = async (e) => {
        //e.preventDefault();
        mutation.mutate({id:data.id, genre:data.genre})
        
        setShowRemoveConfirmation(false);
        navigate("/")
        
    };
    
      const handleConfirmNo = () => {
        // Perform action when user confirms "No" or cancels
        setShowRemoveConfirmation(false);
        // Add any necessary cleanup logic
      };
   

    return (
        <div className="singleRecord">
            <Navbar />
            <MediaQuery maxWidth={600}>
            <div className="singleReviewContent">
                <img src={NavigationLeftGold} 
                     className="singleReviewBackButton"
                     onClick={() =>navigate(-1)} />
                <div className="singleTopInfo">
                    <img src={"/profile_pictures/"+reviewerPic} />
                    <p>
                    Recenserad av {data.reviewedBy} 
                                <span style={{color: "#E6C068"}}> | </span>
                                {formatDate(data.reviewedAt)} 
                                <span style={{color: "#E6C068"}}> | </span> 
                                <span style={{color: "#E6C068"}}>
                                    {data.genre.toUpperCase() == "ANNAT" ? "FLER GENRER"
                                    : data.genre.toUpperCase()}
                                </span>
                    </p>
                </div>
                <div className="singleRecordImage">
                    <img src={"/upload/"+data.image} />
                </div>

                    <p className="singleArtist">{data.artist}</p>
                    <p className="singleRecordName">{data.recordName}</p>

                    <div className="singleRecordInfo">
                        <p className="singleReleaseYear">Releaseår: {data.releaseYear}</p>
                        <p className="singlePlayTime">Speltid: {data.playTime}</p>
                    </div>
                
                
               

                <div className="singleReview">
                    <Editor editorState={editorState} readOnly={true} />
                </div>
            </div>
            <Footer />
            </MediaQuery>

            <MediaQuery minWidth={601} maxWidth={1000}> {/* TABLET */}
                <div className="singleReviewContent">
                    <img src={NavigationLeftGold} 
                        className="singleReviewBackButton"
                        onClick={() =>navigate(-1)} />
                    <div className="singleTopInfo">
                        <img src={"/profile_pictures/"+reviewerPic} />
                        <p>
                                Recenserad av {data.reviewedBy} 
                                <span style={{color: "#E6C068"}}> | </span>
                                {formatDate(data.reviewedAt)} 
                                <span style={{color: "#E6C068"}}> | </span> 
                                <span style={{color: "#E6C068"}}>
                                    {data.genre.toUpperCase() == "ANNAT" ? "FLER GENRER"
                                    : data.genre.toUpperCase()}
                                </span>
                            </p>
                    </div>
                    <div className="singleRecordImage">
                        <img src={"/upload/"+data.image} />
                    </div>

                        <p className="singleArtist">{data.artist}</p>
                        <p className="singleRecordName">{data.recordName}</p>

                        <div className="singleRecordInfo">
                            <p className="singleReleaseYear">Releaseår: {data.releaseYear}</p>
                            <p className="singlePlayTime">Speltid: {data.playTime}</p>
                        </div>
                    
                    
                

                    <div className="singleReview">
                        <Editor editorState={editorState} readOnly={true} />
                    </div>
                </div>
            <Footer />
            </MediaQuery>

            <MediaQuery minWidth={1001} maxWidth={1599}>
                {data &&
                    <div className="singleReviewContent">
                        <div className="singleReviewContentWrapper">
                            <div className="singleReviewBackEdit">
                                <img src={NavigationLeftGold} 
                                    className="singleReviewBackButton"
                                    onClick={() =>navigate(-1)} />

                                { user && 
                                    <div style={{display:"flex"}}>
                                        <Link className="singleRecordEditLink" to={"/edit/"+data.artist+"-"+data.recordName}
                                            state={{
                                                data:data
                                            }}>
                                            <div>
                                                <img src={EditIcon} />
                                                <p style={{marginLeft:"8px"}} >Redigera</p>
                                            </div>
                                        </Link>

                                        <div className="singleRecordRemoveButton"
                                            onClick={handleRemoveClick}
                                        >
                                            <img src={RemoveIcon} />
                                            <p style={{marginLeft:"8px"}} >Ta bort</p>
                                        </div>
                                        {showRemoveConfirmation && (
                                            <>
                                            <div className="overlay" onClick={handleConfirmNo}></div>
                                            <div className="confirmationPrompt">
                                                <p>Är du säker på att du vill ta bort recensionen?</p>
                                                
                                                <button style={{marginRight:"60px"}} onClick={handleConfirmYes}>JA</button>
                                                <button onClick={handleConfirmNo}>NEJ</button>
                                            </div>
                                            </>
                                        )}
                                    </div>
                                }
                        </div>
                        
                    
                    
                        <div className="singleTopInfo">
                            <img src={"/profile_pictures/"+reviewerPic} />
                            <p>
                                Recenserad av {data.reviewedBy} 
                                <span style={{color: "#E6C068"}}> | </span>
                                {formatDate(data.reviewedAt)} 
                                <span style={{color: "#E6C068"}}> | </span> 
                                <span style={{color: "#E6C068"}}>
                                    {data.genre.toUpperCase() == "ANNAT" ? "FLER GENRER"
                                    : data.genre.toUpperCase()}
                                </span>
                            </p>
                        </div>

                        <p className="singleArtist">{data.artist}</p>
                        <p className="singleRecordName">{data.recordName}</p>

                        <div className="singleReviewRecordInfo">
                            <p className="singleReleaseYear">Releaseår: {data.releaseYear}</p>
                            <p className="singlePlayTime">Speltid: {data.playTime}</p>
                        </div>

                        <div className="singleReviewTextImage">
                            <div className="singleReviewText">
                                <Editor editorState={editorState} readOnly={true} />
                            </div>
                        
                            <div className="singleReviewImage">
                                <img src={"/upload/"+data.image} />
                            </div>
                        </div>
                    
                    {/*<CommentSection id={data.id} />*/}
                    </div>
                </div>
                }
                <Footer />
            </MediaQuery>

            <MediaQuery minWidth={1600}>
                
                {data &&
                <div className="singleReviewContent">
                    <div className="singleReviewContentWrapper">
                        <div className="singleReviewBackEdit">
                            <img src={NavigationLeftGold} 
                                className="singleReviewBackButton"
                                onClick={() =>navigate(-1)} />

                            { user && 
                                <div style={{display:"flex"}}>
                                    <Link className="singleRecordEditLink" to={"/edit/"+data.artist+"-"+data.recordName}
                                        state={{
                                            data:data
                                        }}>
                                        <div>
                                            <img src={EditIcon} />
                                            <p style={{marginLeft:"8px"}} >Redigera</p>
                                        </div>
                                    </Link>

                                    <div className="singleRecordRemoveButton"
                                        onClick={handleRemoveClick}
                                    >
                                        <img src={RemoveIcon} />
                                        <p style={{marginLeft:"8px"}} >Ta bort</p>
                                    </div>
                                    {showRemoveConfirmation && (
                                        <>
                                        <div className="overlay" onClick={handleConfirmNo}></div>
                                        <div className="confirmationPrompt">
                                            <p>Är du säker på att du vill ta bort recensionen?</p>
                                            
                                            <button style={{marginRight:"60px"}} onClick={handleConfirmYes}>JA</button>
                                            <button onClick={handleConfirmNo}>NEJ</button>
                                        </div>
                                        </>
                                    )}
                                </div>
                            }
                        </div>
                        
                    
                    
                        <div className="singleTopInfo">
                            <img src={"/profile_pictures/"+reviewerPic} />
                            <p>
                                Recenserad av {data.reviewedBy} 
                                <span style={{color: "#E6C068"}}> | </span>
                                {formatDate(data.reviewedAt)} 
                                <span style={{color: "#E6C068"}}> | </span> 
                                <span style={{color: "#E6C068"}}>
                                    {data.genre.toUpperCase() == "ANNAT" ? "FLER GENRER"
                                    : data.genre.toUpperCase()}
                                </span>
                            </p>
                        </div>

                        <p className="singleArtist">{data.artist}</p>
                        <p className="singleRecordName">{data.recordName}</p>

                        <div className="singleReviewRecordInfo">
                            <p className="singleReleaseYear">Releaseår: {data.releaseYear}</p>
                            <p className="singlePlayTime">Speltid: {data.playTime}</p>
                        </div>

                        <div className="singleReviewTextImage">
                            <div className="singleReviewText">
                                <Editor editorState={editorState} readOnly={true} />
                            </div>
                        
                            <div className="singleReviewImage">
                                <img src={"/upload/"+data.image} />
                            </div>
                        </div>
                    
                    {/*<CommentSection id={data.id} />*/}
                    </div>
                </div>
                }
                <Footer />
            </MediaQuery>
        </div>
    )
}