import { useContext, useState } from "react";
import Navbar from "../../components/Navbar/Navbar"
import Footer from "../../components/footer/Footer"
import "./profile.css"
import { AuthContext } from "../../context/authContext";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { makeRequest } from "../../axios";

import baseUrl from "../../baseUrl"

export default function Profile() {
    const navigate = useNavigate();

    const {user, logout} = useContext(AuthContext);

    const [newPassword, setNewPassword] = useState("")
    const [repeatedNewPassword, setRepeatedNewPassword] = useState("")
    const [passwordIsValid, setPasswordIsValid] = useState(false)

    const passwordValidationRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;

    function logoutRedirect(){
        logout()
        navigate("/");
    }

    const queryClient = useQueryClient();
    const mutation = useMutation(
        (pwChange) => {
            return makeRequest.post("https://"+baseUrl+"/api/changepassword", pwChange);
        },
        {
            onSuccess: () => {
              // Invalidate and refetch
              queryClient.invalidateQueries(["changepassword"]);
  
              
            },
          }
    )

    const handleChangePassword = async (e) => {
        e.preventDefault()
        setPasswordIsValid(passwordValidationRegex.test(newPassword))
        if(newPassword == repeatedNewPassword && passwordIsValid){
            mutation.mutate({email:user.email, new_password:newPassword})
        }
    }

    return (
        <div className="profile">
            <Navbar />
            <div className="profileWrapper">
                <div className="profileHeader">
                    <div className="profileHeaderText">Min profil</div>
                    <button 
                        className="profileLogoutButton"
                        onClick={logoutRedirect}
                    >
                        Logga ut
                    </button>
                </div>

                <div className="profilePasswordSection">
                    <p className="profilePasswordSectionHeader">Byt lösenord</p>
                    <div className="profilePasswordSentionRules"
                        style={{border:passwordIsValid ? "" : "2px solid red"}}
                    >
                        <p>Lösenordet måste innehålla följande:</p>
                        <ul>
                            <li>Minst 8 tecken</li>
                            <li>Minst en stor och en liten bokstav</li>
                            <li>Minst en siffra</li>
                        </ul>
                    </div>

                    <div className="profilePasswordForm">
                        <p className="profileInputFieldDesc">Nytt lösenord</p>
                        <input 
                            type="password" 
                            placeholder="Skriv ditt nya lösenord här..." 
                            onChange={(e) => setNewPassword(e.target.value)} 
                            name="password" 
                        />

                        <p style={{marginTop:"30px"}} className="profileInputFieldDesc">Upprepa nytt lösenord</p>
                        <input 
                            type="password" 
                            placeholder="Skriv ditt nya lösenord här..." 
                            onChange={(e) => setRepeatedNewPassword(e.target.value)} 
                            name="password" 
                        />

                        <button onClick={handleChangePassword}>Spara</button>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}